import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
export const Head = () => {
  return (
    <Seo
      title="鍼灸エステ一花堂"
      description="鍼灸エステ一花堂は、男女問わずマツエクをはじめ脱毛メニューがお手軽に施術できる美容サロンです。美脚マッサージやフェイシャル・ドームサウナも完備しているので、身体のメンテナンスから髪のお手入れまでおまかせください。"
    />
  );
};

const IkkadouPage = () => {
  const navLinks2 = [
    { to: "/", text: "トップ" },
    { to: "/ikkadou-menu", text: "一花堂メニュー" },
    { to: "#access", text: "アクセス・お問い合わせ" },
  ];
  const navLinks = [
    {
      id: 1,
      name: "トップ",
      link: "/",
      dropdown: false,
    },
    {
      id: 2,
      name: "一花堂メニュー",
      link: "/ikkadou-menu",
      dropdown: false,
    },
    {
      id: 3,
      name: "アクセス・お問い合わせ",
      link: "#access",
      dropdown: false,
    },
    {
      id: 4,
      name: "各店舗",
      dropdown: true,
      subItems: [
        { name: "鎌田鍼灸整骨院", link: "/" },
        { name: "守恒鍼灸美容館", link: "/biyoukan" },
        { name: "一花堂", link: "/ikkadou" },
      ],
    },
  ];

  return (
    <Layout navLinks={navLinks} color={"rgb(50, 100, 177, 0.2)"}>
      <div className="image-heading">
        <StaticImage
          src="../images/ikkadou_main.png"
          width={4000}
          alt="topの画像"
        />
      </div>
      <div className="kamata-body">
        <main>
          <div id="about" className="aboutusbox">
            <h2 className="aboutus">
              <small>鍼灸エステ</small>
              <strong> 一 花 堂 </strong>について
            </h2>
            <div className="aboutbodyleft">
              <p>
                鍼灸エステ一花堂は、（株）政鍼SeishiNグループのエステ分野を担い、脱毛（メンズ・レディース）はじめマツエクやオールハンド施術のリンパマッサージ、美顔鍼などが１フロアで完結できるトータルビューティーサロンです。
              </p>
              <p>
                本店の鎌田鍼灸整骨院、姉妹店の守恒鍼灸美容館と連携し、身体のケアから髪のお手入れまで、すべてのお客様のご要望を叶える体制でお迎えしております。
              </p>
              <p>
                全室個室対応・完全予約制で国家資格者による衛生管理を徹底し、感染症対策も万全です。安心してご利用下さい。
              </p>
              <p>
                当店は、モノレール守恒駅から徒歩１分の立地にありながら、無料駐車場も完備しております。皆さまのご利用をお待ちしております。
              </p>
              <p>
                （★脱毛の一部については、 ビューティーサロンあふりかぞう
                に運営を委託しております。）
              </p>
            </div>
            <div className="to-top-box">
              <Link to="/ikkadou-menu" className="menu-button">
                鍼灸エステ一花堂のメニューはこちら
              </Link>
            </div>
          </div>
          <div id="access" className="accessCard">
            <h3 className="accessCardHeader">アクセス・電話番号</h3>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.0427252812456!2d130.8717237506088!3d33.83700878057021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3543c7fa339084ff%3A0xad4bfc371c33eb61!2z6Y2854G444Ko44K544OG5LiA6Iqx5aCC!5e0!3m2!1sja!2sjp!4v1670132289346!5m2!1sja!2sjp"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            <table>
              <tr>
                <th>住所:</th>
                <td>福岡県北九州市小倉南区守恒本町２丁目1−18 2F</td>
              </tr>
              <tr>
                <th>アクセス:</th>
                <td>
                  守恒駅から120m （ 競馬場前(福岡)駅から700m
                  徳力公団前駅から950m ）
                </td>
              </tr>
              <tr>
                <th>電話番号:</th>
                <td>080-3946-3020</td>
              </tr>
            </table>
          </div>
          <div className="to-top-box">
            <Link to="/" className="menu-button">
              トップページに戻る
            </Link>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default IkkadouPage;
